import { useTranslation } from 'next-i18next'
import type { ReactNode } from 'react'
import { cn } from '~/lib/utils'

export type Suffix =
	| 'hourly'
	| 'daily'
	| 'monthly'
	| 'hourlyLong'
	| 'dailyLong'
	| 'monthlyLong'
	| 'person'
	| 'forDelivery'

export type Taxes = 'ht' | 'ttc'

export interface PriceProps {
	price?: number
	suffix?: Suffix
	taxType?: Taxes
	crossed?: boolean
	isMinus?: boolean
	isPlus?: boolean
	className?: string
	taxClassName?: string
	suffixClassName?: string
	suffixComponent?: ReactNode
}

const suffixRefs: Record<Suffix, string> = {
	hourly: 'common:currency.suffix.hourly',
	daily: 'common:currency.suffix.daily',
	monthly: 'common:currency.suffix.monthly',
	hourlyLong: 'common:currency.suffix.hourlyLong',
	dailyLong: 'common:currency.suffix.dailyLong',
	monthlyLong: 'common:currency.suffix.monthlyLong',
	person: 'common:currency.suffix.person',
	forDelivery: 'extras:mentionFees',
}

const taxsRefs: Record<Taxes, string> = {
	ht: 'common:currency.suffix.taxHt',
	ttc: 'common:currency.suffix.taxTtc',
}

export const Price = ({
	price,
	crossed,
	suffix,
	suffixComponent,
	taxType,
	isMinus,
	isPlus,
	className,
	taxClassName,
	suffixClassName,
}: PriceProps) => {
	const { t } = useTranslation()

	if (price === undefined) {
		return (
			<div
				className={cn(
					'relative inline-flex items-end gap-1 font-bold leading-none',
					className,
				)}
			></div>
		)
	}

	const priceTrad = !!Number(price.toString().slice(-2)) // if decimals -> full variant
		? 'common:currency.full'
		: 'common:currency.short'

	return (
		<div
			className={cn(
				'relative inline-flex items-end gap-1 font-bold leading-none',
				className,
			)}
		>
			{isMinus && '-'}

			{isPlus && '+ '}

			{t(priceTrad, { val: price / 100 })}

			{(taxType || suffix) && (
				<div className='flex items-end leading-none'>
					{taxType && (
						<span
							className={cn('font-normal text-xxs leading-none', taxClassName)}
						>
							{t(taxsRefs[taxType])}
						</span>
					)}

					{!suffixComponent && suffix && (
						<span
							className={cn(
								'font-normal text-xs leading-none',
								suffixClassName,
							)}
						>
							{t(suffixRefs[suffix])}
						</span>
					)}

					{suffixComponent}
				</div>
			)}

			{crossed && (
				<hr className='-mt-px absolute top-1/2 left-0 w-full border-black' />
			)}
		</div>
	)
}
