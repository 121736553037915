import type { Space } from '@cocoonspace/sdk-js/types/space'
import { TransportationLine } from '~/domains/spaces/components/atoms/transportation-line'
import { useSpaceStations } from '~/domains/spaces/hooks/use-space-stations.hook'
import { cn } from '~/lib/utils'

export const SpaceCardNearestStation = ({
	space,
	className,
}: {
	space: Space
	className?: string
}) => {
	const { stations } = useSpaceStations(space)
	const station = stations?.[0]
	const line = station?.lines?.[0]

	if (!line) return null

	return (
		<div
			className={cn(
				'flex flex-1 flex-row items-center justify-start gap-x-1 truncate',
				className,
			)}
		>
			<TransportationLine
				space={space}
				stationName={station.name || ''}
				line={line}
				iconSize={16}
			/>

			<div className='flex-1 truncate text-xs'>{station.name}</div>
		</div>
	)
}
